import React, {useEffect, useState} from "react";

import Layout from "../components/common/layout/layout";
import SEO from "../components/common/SEO/SEO";
import Thanks from "../components/shop/thanks/Thanks";

const ThanksPage = () => {

  const [sessionId, setSessionId ] = useState(null);
  const [selectedFlowers, setSelectedFlowers ] = useState(null);

  useEffect(()=> {
    const params = new URLSearchParams(location.search);
    setSessionId(params.get("sessionId"));
    const _selectedFlowers = params.get("flores") ? params.get("flores") : "";
    setSelectedFlowers(_selectedFlowers);
  }, []);


  return (
    <Layout>
      <SEO
        title="Gracias por tu compra en AlmaySer"
        description=""
        canonical={"gracias"}
        robots={"noindex"}
      />
    <h1 className={"is-text-center"}>Gracias por confiar en nosotras</h1>
      {sessionId &&
        <Thanks sessionId={sessionId} selectedFlowers={selectedFlowers}/>
      }
      {!sessionId &&
        <p className={"text-center"}>Es posible que hayas llegado a esta página por error </p>
      }

    </Layout>
  );
};

export default ThanksPage;

