import { MyResponse, ValidateCheckout } from "../domain/CheckoutRepository";

export const validateCheckoutRepository: ValidateCheckout = (
  sessionId: string,
  selectedFlowers: string
): Promise<MyResponse> => {
  return new Promise<MyResponse>((resolve, reject) => {
    fetch(`/.netlify/functions/success-checkout`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        sessionId,
        selectedFlowers,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        resolve({
          statusCode: 200,
          body: data,
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
};
