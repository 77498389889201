import "./thanks.scss";

import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { PropsWithChildren, useEffect, useState } from "react";

import { MyResponse } from "../../../contexts/shop/domain/CheckoutRepository";
import { ProductsTypes } from "../../../contexts/shop/domain/products-types";
import { Resume } from "../../../contexts/shop/domain/Resume";
import { validateCheckoutRepository } from "../../../contexts/shop/infrastructure/FetchCheckoutRepository";

interface PropsThanks extends PropsWithChildren<any> {
  sessionId: string;
  selectedFlowers?: string;
}

const Thanks: React.FC<PropsThanks> = (props: PropsThanks) => {
  const [status, setStatus] = useState<number>(0);
  const [resume, setResume] = useState<Resume | string>({
    address: "",
    description: "",
  });

  const { sessionId } = props;
  const selectedFlowers: string = props.selectedFlowers
    ? props.selectedFlowers
    : "";

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  useEffect(async () => {
    const value: MyResponse = await validateCheckoutRepository(
      sessionId,
      selectedFlowers
    );
    setStatus(value.statusCode);
    setResume(value.body);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dataLayer.push({
      event: "purchase",
      ecommerce: {
        purchase: {
          actionField: {
            id: `${value.body.transaction_id}`, // Transaction ID. Required for purchases and refunds.
            affiliation: "Almayser",
            revenue: `${value.body.amount_total}`, // Total transaction value (incl. tax and shipping)
            tax: `${value.body.amount_total * 0.21}`,
            shipping: "0",
            coupon: "",
          },
          products: [
            {
              // List of productFieldObjects.
              name: `${value.body.description}`, // Name or ID is required.
              id: `${value.body.type}`,
              price: `${value.body.amount_total}`,
              brand: "Almayser",
              category: `${value.body.type}`,
              variant: `${value.body.flowers ? value.body.flowers : ""}${
                value.body.preservative
                  ? " - con conservante"
                  : " - sin conservante"
              }`, //optional fields
              preservative: value.body.preservative,
              quantity: 1,
              coupon: "", // Optional fields may be omitted or set to empty string.
            },
          ],
        },
      },
    });
  }, []);

  return (
    <section className={"o-thanks container"}>
      {status === 0 && (
        <div className={"m-thanks__loading"}>
          <p className={"text-center"}>
            {" "}
            Validando pago <br />
            <span className={"a-spinner"} />{" "}
          </p>
          <StaticImage
            src={"../../../assets/vectors/green-circle.svg"}
            className={"a-circle-rotate"}
            alt={"Círculo"}
          />
        </div>
      )}
      {status === 500 && <h2>Ha ocurrido un error</h2>}
      {status === 200 &&
        typeof resume === "object" &&
        (resume.type === ProductsTypes.Remedy_custom ||
          resume.type === ProductsTypes.Preparation_custom) && (
          <>
            Tu <b>{resume.description}</b> será enviado a <br />
            <b>{resume.address}</b>
            {resume.flowers && (
              <>
                <br /> Con las siguientes flores: <b>{resume.flowers}</b>
              </>
            )}
          </>
        )}
      {status === 200 &&
        typeof resume === "object" &&
        resume.type === ProductsTypes.Remedy && (
          <>
            Tu <b>{resume.description}</b> será enviado a <br />
            <b>{resume.address}</b>
          </>
        )}
      {status === 200 &&
        typeof resume === "object" &&
        resume.type === ProductsTypes.Email_consultation && (
          <>
            Tu <b>{resume.description}</b> ha sido enviada con exito <br />
          </>
        )}
    </section>
  );
};

export default Thanks;
